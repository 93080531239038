/* Provide sufficient contrast against white background */
a {
   color: #0366d6;
}

code {
   color: #e01a76;
}

.btn-primary {
   color: #fff;
   background-color: #1b6ec2;
   border-color: #1861ac;
}

.app-wrapper {
   min-width: 1300px;
}

/* Toast UI 기본 폰트 사이즈 변경 css */
.tui-editor-contents {
   font-family: "맑은 고딕";
   font-size: 15px;
}

.titleArea {
   width: 100%;
}

@media print {
   * {
      -webkit-print-color-adjust: exact;
   }

   html,
   body {
      width: 210mm;
      height: 297mm;
      padding: 0px;
      margin-top: -50px;
      margin-bottom: 0px;
   }

   body * {
      visibility: hidden;
   }

   .printArea,
   .printArea * {
      font-family: "Apple SD Gothic Neo", "Malgun Gothic", sans-serif;
      font-size: 18pt;
      word-break: keep-all;
      visibility: visible;
   }

   table,
   tr,
   th,
   td {
      font-size: 14pt !important;
   }

   .titleArea,
   .titleArea * {
      font-size: 24pt !important;
      font-weight: bold !important;
   }

   .printNone,
   .printNone * {
      display: none !important;
   }
   .pagebreak {
      page-break-before: always;
   }
}
